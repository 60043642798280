import { configureStore } from '@reduxjs/toolkit';
import accountSliceReducer from '../../pages/accounting/accountSlice/account.slice';
import orderSliceReducer from '../../pages/orders/slices/order.slice';
import productSliceReducer from '../../pages/products/productSlices/product.slice';
import settingSliceReducer from '../../pages/settings/settingSlices/setting.slice';
import businessTypeSliceReducer from '../slices/businessTypeSlice';
import documentTypeSelectReducer from '../slices/documentTypeSlice';
import getStatesReducer from '../slices/get_state.slice';
import OnboardingStepReducer from '../../pages/signUp/slices/onboarding_steps.slice';
import OnboardingFormReducer from '../../pages/signUp/slices/onboarding_form.slice';
import OnboardVendorReducer from '../../pages/signUp/slices/onboard_vendor.slice';
import verifySignupOtpReducer from '../../pages/signUp/slices/signup_otp.slice';
import loginVendorReducer from '../../pages/login/slices/loginSlice';
import verifyLoginOtpReducer from '../../pages/login/slices/login_otp.slice';
import pendingOrderReducer from '../../pages/orders/slices/pending_order_slice';
import totalNumberOfProductReducer from '../../pages/products/productSlices/number_of_product_slice';
import listOfProductReducer from '../../pages/products/productSlices/lists_of_products_slice';
import whoAmIReducer from '../slices/who_am_i.slice';
import createProductReducer from '../../pages/products/productSlices/create_product_slice';
import updateProductReducer from '../../pages/products/productSlices/update_product_slice';
import totalPlacedOrdersReducer from '../../pages/orders/slices/total_placed_order_slice';
import confirmOrderReducer from '../../pages/orders/slices/confirm_order_slice';
import ongoingOrderReducer from '../../pages/orders/slices/ongoing_order_slice';
import totalOrdersPerDayReducer from '../../pages/orders/slices/total_orders_per_day_slice';
import getBalanceReducer from '../../pages/accounting/accountSlice/get_balance_slice';
import declineOrderReducer from '../../pages/orders/slices/decline_order_slice';
import changePasswordReducer from '../../pages/settings/settingSlices/change_password_slice';
import updateNotificationReducer from '../../pages/settings/settingSlices/update_notification_slice';
import userDetailsReducer from '../../pages/settings/settingSlices/user_details_slice';
import getBanksReducer from '../../pages/settings/settingSlices/banks_slice';
import addPaymentDetailsReducer from '../../pages/settings/settingSlices/add_payment_details';
import advertSliceReducer from '../../pages/adverts/slices/advert_slice';
import totalNumberOfAdvertReducer from '../../pages/adverts/slices/total_number_adverts_slice';
import listOfPendingAdvertsReducer from '../../pages/adverts/slices/list_of_pending_adverts_slice';
import totalNumberOfActiveOrdersReducer from '../../pages/orders/slices/active_order_value_slice';
import orderDetailsReducer from '../../pages/orders/slices/get_order_details';
import activeOrderItemReducer from '../../pages/orders/slices/active_order_slice';
import readyOrderReducer from '../../pages/orders/slices/order_ready_slice';
import orderHistoryReducer from '../../pages/orders/slices/order_history_slice';
import activePromoReducer from '../../pages/promotion/slices/active_promo_slice';
import createPromoReducer from '../../pages/promotion/slices/create_promo_slice';
import promoSliceReducer from '../../pages/promotion/slices/promo_slice';
import totalNumberOfPromoReducer from '../../pages/promotion/slices/number_of_promo_slice';
import updatePromoReducer from '../../pages/promotion/slices/update_promo_slice';
import totalNumberOfActivePromoReducer from '../../pages/promotion/slices/number_of_active_promo_slice';
import totalEarnedWithinDateRangeReducer from '../../pages/analytics/slices/total_earnings_with_date_slice';
import getRatingReducer from '../../pages/reviews/slices/get_rating_slice';
import getReviewReducer from '../../pages/reviews/slices/get_review_slice';
import requestPayoutReducer from '../../pages/accounting/accountSlice/request_payout_slice';
import expiredPromoReducer from '../../pages/promotion/slices/expired_promo_slice';
import getAllPendingPayoutRequestReducer from '../../pages/accounting/accountSlice/get_all_payout_request_slice';
import getPayoutHistoryReducer from '../../pages/accounting/accountSlice/get_all_payout_history_slice';
import getProductDetailsReducer from '../../pages/products/productSlices/get_product_details_slice';
import getAmountPerDayReducer from '../../pages/adverts/slices/get_amount_per_day_slice';
import promoDetailsReducer from '../../pages/promotion/slices/get_promo_details';
import reactivatePromoReducer from '../../pages/promotion/slices/reactivate_promo_slice';
import listOfActiveAdvertsReducer from '../../pages/adverts/slices/list_of_active_adverts_slice';
import listOfExpiredAdvertsReducer from '../../pages/adverts/slices/list_of_expired_adverts_slice';
import verifyBankDetailsReducer from '../../pages/settings/settingSlices/verify_bank_details';
import getBankDetailsReducer from '../../pages/settings/settingSlices/get_bank_details_slice';
import replyReviewReducer from '../../pages/reviews/slices/reply_review_slice';
import deleteProductReducer from '../../pages/products/productSlices/delete_product_slice';
import requestResetPasswordReducer from '../../pages/forgotPassword/slices/request_reset_password_slice';
import verifyResetPasswordOtpReducer from '../../pages/forgotPassword/slices/verify_reset_password_otp_slice';
import resetPasswordReducer from '../../pages/forgotPassword/slices/reset_password_slice';
import getProductStockReducer from '../../pages/products/productSlices/out_of_stock_slice';
import updateProductStatusReducer from '../../pages/products/productSlices/update_product_status_slice';
import totalOrdersReducer from '../../pages/orders/slices/total_orders_slice';
import getCommissionReducer from '../../pages/products/productSlices/get_vendor_commission';
import vendorSignUpReducer from '../../pages/signUp/slices/sign_up.slice';
import uploadProductReducer from '../../pages/products/productSlices/upload_product_slice';
import uploadLogoReducer from '../../pages/settings/settingSlices/upload_logo_slice';
import uploadProofOfAddressReducer from '../../pages/signUp/slices/upload_proof_address.slice';
import getVendorInventoriesReducer from '../../pages/products/productSlices/get_inventories_slice';
import getVendorInventoryReducer from '../../pages/products/productSlices/get_inventory_slice';
import addInventoryDiscountReducer from '../../pages/products/productSlices/add_inventory_discount_slice';
import resolveBankAccountReducer from '../../pages/settings/settingSlices/resolve_bank_account_slice';
import confirmBankAccountReducer from '../../pages/settings/settingSlices/confirm_bank_account_slice';
import getBankAccountReducer from '../../pages/settings/settingSlices/get_bank_accounts_slice';

export const store = configureStore({
  reducer: {
    productPage: productSliceReducer,
    accountPage: accountSliceReducer,
    settingPage: settingSliceReducer,
    orderPage: orderSliceReducer,
    advertPage: advertSliceReducer,
    businessType: businessTypeSliceReducer,
    documentType: documentTypeSelectReducer,
    getStates: getStatesReducer,
    onboardingStep: OnboardingStepReducer,
    onboardingForm: OnboardingFormReducer,
    onboardVendor: OnboardVendorReducer,
    verifySignUpOtp: verifySignupOtpReducer,
    verifyLoginOtp: verifyLoginOtpReducer,
    login: loginVendorReducer,
    pendingOrder: pendingOrderReducer,
    activeOrder: activeOrderItemReducer,
    numberOfProducts: totalNumberOfProductReducer,
    listOfProducts: listOfProductReducer,
    whoAmI: whoAmIReducer,
    createProduct: createProductReducer,
    updateProduct: updateProductReducer,
    totalPlacedOrder: totalPlacedOrdersReducer,
    confirmOrder: confirmOrderReducer,
    declineOrder: declineOrderReducer,
    ongoingOrder: ongoingOrderReducer,
    totalOrdersPerDay: totalOrdersPerDayReducer,
    getBalance: getBalanceReducer,
    changePassword: changePasswordReducer,
    updateNotification: updateNotificationReducer,
    userDetails: userDetailsReducer,
    getBanks: getBanksReducer,
    addPaymentDetails: addPaymentDetailsReducer,
    totalAdvert: totalNumberOfAdvertReducer,
    listOfPendingAdverts: listOfPendingAdvertsReducer,
    totalActiveOrderValue: totalNumberOfActiveOrdersReducer,
    getOrderItemsDetails: orderDetailsReducer,
    readyOrder: readyOrderReducer,
    orderItemHistory: orderHistoryReducer,
    createPromo: createPromoReducer,
    promoPage: promoSliceReducer,
    getActivePromo: activePromoReducer,
    numberOfPromo: totalNumberOfPromoReducer,
    updatePromo: updatePromoReducer,
    totalActivePromo: totalNumberOfActivePromoReducer,
    totalEarned: totalEarnedWithinDateRangeReducer,
    getRating: getRatingReducer,
    getReview: getReviewReducer,
    requestPayout: requestPayoutReducer,
    getExpiredPromo: expiredPromoReducer,
    getPayoutRequest: getAllPendingPayoutRequestReducer,
    getPayoutHistory: getPayoutHistoryReducer,
    getProductDetails: getProductDetailsReducer,
    amountPerDay: getAmountPerDayReducer,
    getPromoDetails: promoDetailsReducer,
    reactivatePromo: reactivatePromoReducer,
    activeAdverts: listOfActiveAdvertsReducer,
    expiredAdverts: listOfExpiredAdvertsReducer,
    verifyBank: verifyBankDetailsReducer,
    getBankDetails: getBankDetailsReducer,
    replyReview: replyReviewReducer,
    deleteProduct: deleteProductReducer,
    requestResetPassword: requestResetPasswordReducer,
    verifyResetPasswordOtp: verifyResetPasswordOtpReducer,
    resetPassword: resetPasswordReducer,
    getProductStock: getProductStockReducer,
    updateProductStatus: updateProductStatusReducer,
    getTotalOrders: totalOrdersReducer,
    getCommission: getCommissionReducer,
    signUp: vendorSignUpReducer,
    uploadProduct: uploadProductReducer,
    uploadLogo: uploadLogoReducer,
    uploadProofOfAddress: uploadProofOfAddressReducer,
    getVendorInventories: getVendorInventoriesReducer,
    getVendorInventory: getVendorInventoryReducer,
    addInventoryDiscount: addInventoryDiscountReducer,
    resolveBankAccount: resolveBankAccountReducer,
    confirmBankAccount: confirmBankAccountReducer,
    getBankAccount: getBankAccountReducer,
  },
});

/**
 * See documentation https://redux-toolkit.js.org/tutorials/typescript
 */
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
